import React from "react"
import Image from "gatsby-image";
import "../../styles/offer.scss"
import OfferHeader from "../../components/OfferHeader";
import OfferItem from "../../components/OfferItem"
import Realizations from "../../components/Realizations"
import colorImg from "../../images/upholstery/main/uphol_medicine3.svg"
import SEO from "../../components/seo"
import { graphql } from "gatsby"

const dataUpholstery = {
    header: "Jesteś zainteresowany konkretnym obiciem? Zgłoś się do nas",
    text: "Zajmujemy się szeroko pojętym tapicerstwem: meblowym, medycznym, samochodowym i jachtowym. Podejmujemy się również nietypowych zleceń, jedynym ograniczeniem jest wyobraźnia klienta.",
    link: "cennik/tapicerstwo-medyczne",
    headerItem1: "Sprawdź naszą kompleksową ofertę tapicerowania mebli",
    descItem1: "Wykonujemy tapicerowanie mebli skórzanych, a także wyposażenia obiektów użyteczności publicznej. Każdy mebel przygotowujemy starannie, by był idealnie dopasowany do wnętrza, w którym ma się znaleźć. Zapewniamy profesjonalne tapicerowanie Twojego fotela, kanapy czy krzeseł.",
    headerItem2: "Zajmujemy się renowacją mebli tapicerowanych",
    descItem2: "Dlaczego warto zdecydować się na nasze usługi tapicerskie? Nowa, trwalsza i bardziej estetyczna tapicerka skutecznie odmieni wygląd podniszczonych krzeseł, sof czy kanap. Nadamy im eleganckiego i luksusowego wyglądu. Znajdziesz u nas eleganckie materiały obiciowe, a także dodatki tapicerskie.",
    headerItem3: "Wymieniamy i zabezpieczamy tapicerkę medyczną",
    descItem3: "Prócz wymiany tapicerki na m.in fotelach stomatologicznych czy kozetkach, szyjemy pokrowce i specjalne osłony zabezpieczające oryginalną powierzchnię przed nadmiernym zużyciem. Stosujemy sprawdzone materiały typu skaj posiadające atest medyczny.",
    headerItem4: "Wykonujemy na zamówienie wałki rehabilitacyjne.",
    descItem4: "U nas zamówisz wałki rehabilitacyjne odpowiadające Twoim potrzebom - różne kształty, wymiary oraz twardość.",
    headerItem5: "Oferujemy bogatą paletę kolorów materiałów powlekanych Skaden",
    descItem5: "Skaj to wysokiej jakości tkanina odporna na uszkodzenia mechaniczne, łatwo utrzymać ją w czystości, a ponadto jest nieprzemakalna. Materiał ten wyróżnia się elastycznością i doskonałą imitacją ekoskóry. Posiada atest medyczny. <- ostatnie zdanie do dopytania czy ta skaja ma atest",
    headerItem6: "Szyjemy i naprawiamy tapicerkę samochodową w pełnym zakresie",
    descItem6: "Nasze usługi obejmują: samochody osobowe, ciężarowe, maszyny rolnicze, tiry oraz sprzęt budowlany (koparki, walce, ciągniki). Zajmujemy się również obszywaniem siedzisk do motocykli czy quad’ów.",
    headerItem7: "Dopasuj wnętrze samochodu do swojego gustu",
    descItem7: "Zajmujemy się naprawą i wymianą tapicerki samochodowej. Wykonujemy: nowe pokrowce na siedzenia, mieszki do skrzyni biegów i hamulca ręcznego, a także naprawę podsufitki samochodowej. Nie obca jest nam również: renowacja i regeneracja tapicerki skórzanej, obszywanie foteli samochodowych, malowanie skóry samochodowej.",
    headerItem8: "Posiadamy bogatą ofertę trwałych tkanin samochodowych",
    descItem8: "M.in. skóry, filce, skaj (?) Serdecznie zachęcamy do zapoznania się z naszą pełną ofertą wykorzystywanych przez nas materiałów. ",
    headerItem9: "Zajmiemy się tapicerką Twojego jachtu",
    descItem9: "Wykonujemy kompleksowe tapicerowanie zewnętrzne i wewnętrzne. U nas odnowisz fotel sternika, leżaki, skórzane kanapy, obicia ścian łodzi czy inne skórzane elementy. Zajmujemy się również odnowieniem foteli do motorówki. Szyjemy i naprawiamy plandeki na jachty, łodzie czy motorówki.",
    headerItem10: "Używamy materiałów wodoodpornych i wytrzymałych",
    descItem10: "Do produkcji tapicerek zewnętrznych wykorzystujemy materiały dedykowane dla branży żeglarskiej, jednak jeśli klient wyrazi taką chęć, możemy wykonać tapicerkę również z materiałów powierzonych przez klienta, niezależnie od typu materiału.",
    realizationHeader: "Nasze przykładowe realizacje",
    relazationDesc: "Realizujemy różnorodne prace krawieckie. Zapewniamy pełen profesjonalizm, nasze wieloletnie doświadczenie w branży pozwala nam wykonywać usługi solidnie, szybko i za rozsądną cenę."

}

const description = "Wymiana, naprawa tapicerski samochodowej, jachtowej i medycznej. Tapicerowanie, czyszczenie i renowacja mebli. Obijanie mebli skórzanych. Tapicer Konin"
const keywords = ["tapicerka foteli samochodowych", "wymiana tapicerki samochodowej", "naprawa podsufitki", "renowacja tapicerki skórzanej", "obszywanie foteli samochodowych", "tapicerowanie mebli", "tapicerstwo jachtowe", "tapicerowanie mebli skórzanych", "fotel sternika"]
const UpholsteryOfferPage = ({ data }) => (
    <>
        <SEO
            title="Oferta | Tapicer"
            description={description}
            keywords={keywords}
        />
        <section className="offerPage container">
            <OfferHeader
                headerOffer={dataUpholstery.header}
                textOffer={dataUpholstery.text}
                linkOffer={dataUpholstery.link}
            />

            <div className="imgContainer">
                <div className="car"><Image fluid={data.car.childImageSharp.fluid} /></div>
                <div className="rightPanel upholstery">
                    <div className="rowDirection">
                        <div className="yacht"><Image fluid={data.yacht.childImageSharp.fluid} /></div>
                        <div className="medic"><Image fluid={data.medic.childImageSharp.fluid} /></div>
                    </div>
                    <div className="sketch"><Image fluid={data.armchair.childImageSharp.fluid} />
                    </div>

                </div>

            </div>
            <Realizations
                header={dataUpholstery.realizationHeader}
                desc={dataUpholstery.relazationDesc}
                images={data.containers}
                linkPath={"galeria/tapicerstwo"}
            />
            <div className="offerItemsContainer">
                <h2 id="meblowe" className="offerItemsTitle">TAPICERSTWO MEBLOWE</h2>
                <OfferItem
                    headerItem={dataUpholstery.headerItem1}
                    descItem={dataUpholstery.descItem1}
                    img={data.furniture.childImageSharp.fluid}
                />
                <OfferItem
                    headerItem={dataUpholstery.headerItem2}
                    descItem={dataUpholstery.descItem2}
                    img={data.furniture2.childImageSharp.fluid}
                    leftImg
                />
                <h2 id="medyczne" className="offerItemsTitle">TAPICERSTWO MEDYCZNE</h2>
                <OfferItem
                    headerItem={dataUpholstery.headerItem3}
                    descItem={dataUpholstery.descItem3}
                    img={data.medicine.childImageSharp.fluid}
                />
                <OfferItem
                    headerItem={dataUpholstery.headerItem4}
                    descItem={dataUpholstery.descItem4}
                    img={data.medicine2.childImageSharp.fluid}
                    leftImg
                />
                <OfferItem
                    headerItem={dataUpholstery.headerItem5}
                    descItem={dataUpholstery.descItem5}
                    onlySvg={colorImg}
                />
                <h2 id="samochodowe" className="offerItemsTitle">TAPICERSTWO SAMOCHODOWE</h2>
                <OfferItem
                    headerItem={dataUpholstery.headerItem6}
                    descItem={dataUpholstery.descItem6}
                    img={data.car1.childImageSharp.fluid}
                    leftImg
                />
                <OfferItem
                    headerItem={dataUpholstery.headerItem7}
                    descItem={dataUpholstery.descItem7}
                    img={data.car2.childImageSharp.fluid}
                />
                <OfferItem
                    headerItem={dataUpholstery.headerItem8}
                    descItem={dataUpholstery.descItem8}
                    img={data.car3.childImageSharp.fluid}
                    leftImg
                />
                <h2 id="jachtowe" className="offerItemsTitle">TAPICERSTWO JACHTOWE</h2>
                <OfferItem
                    headerItem={dataUpholstery.headerItem9}
                    descItem={dataUpholstery.descItem9}
                    img={data.yacht1.childImageSharp.fluid}
                />
                <OfferItem
                    headerItem={dataUpholstery.headerItem10}
                    descItem={dataUpholstery.descItem10}
                    img={data.yacht2.childImageSharp.fluid}
                    leftImg
                />
            </div>

        </section>
    </>
)

export const query = graphql`
  {
    yacht: file(name: {eq: "uphol_yacht"}) {
        childImageSharp {
             fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    armchair: file(name: {eq: "uphol_armchair"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    car: file(name: {eq: "uphol_car"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    medic: file(name: {eq: "uphol_medic_relu"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    furniture: file(name: {eq: "uphol_furniture"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    furniture2: file(name: {eq: "uphol_furniture2"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    medicine: file(name: {eq: "uphol_medicine"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    medicine2: file(name: {eq: "uphol_medicine2_relu"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    medicine3: file(name: {eq: "uphol_medicine3"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    car1: file(name: {eq: "uphol_car1"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    car2: file(name: {eq: "uphol_car2"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    car3: file(name: {eq: "uphol_car3"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    yacht1: file(name: {eq: "uphol_yacht1"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    yacht2: file(name: {eq: "uphol_yacht3"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },

    containers: allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativeDirectory: {eq: "upholstery/realizations"}}) {
    edges {
      node {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  },

  }
`

export default UpholsteryOfferPage
